import React from 'react';

function CoolStuff() {
  return (
    <section className="cool-stuff">
      <h2>Cool Stuff?</h2>
      <p>I also draw and make some random videos once in a while! Come check it out in this cool gallery.</p>
      <div className="gallery">
        {/* Add your gallery items here */}
      </div>
    </section>
  );
}

export default CoolStuff;